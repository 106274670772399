    import hunt from 'huntjs';

    var h = [];

    // hunt.js and container-wrapper classes if inside hidden tabbed-content area
    if(typeof(hunt) != 'undefined') {
        $('.hunt--fade-slide-reveal').addClass('fade-in-slide-up-init');
        setTimeout(function() {

            h.push(
                new hunt($('.fade-in-slide-up-init'), {
                    enter: function (obj) {
                        $(obj).removeClass('fade-in-slide-up-init').addClass('fade-in-slide-up');
                    }
                })
            );

        }, 1000);
    }