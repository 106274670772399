$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

$('body').on('click', function (e) {
    $('[data-toggle="tooltip"]').each(function () {
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).tooltip('hide');
        }
    });
});