// webfonts
//import './fonts/vag-rounded.css';

// Load in vendor scripts (jquery loaded by Webpack directly);
import 'bootstrap';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'huntjs';

// Load in styles
import './scss/styles.scss';

// Load in modules

import './js/cookie-bar.js';
import './js/emergency-system.js';
import './js/height-match.js';
import './js/hunt-functions.js';
import './js/hero-image.js';
import './js/image-gallery.js';
import './js/navigation.js';
import './js/tabs.js';
import './js/carousel-list.js';
import './js/carousel-swipe.js';
import './js/scroll-to.js';
import './js/tool-tips.js';
import './js/form-validation.js';
import './js/show-more.js';