(function ($, w, d) {

    // tabs (as with all bootstrap bom shenanigans) is async so we need to emulate a callback by using a temporary event
    var scrollToHash = function () {
        $('html, body').animate({
            scrollTop: $(w.location.hash).offset().top
        }, 0);
        // We're done with this event handler
        $(this).off('shown.bs.tab', scrollToHash);
    };

    function showTab() {
        if (w.location.hash.length) {
            var $tab = $('a[href="' + w.location.hash + '"]');
            if (!$tab.hasClass('active')) {
                $tab.on('shown.bs.tab', scrollToHash);
                $tab.tab('show');
            }
        }
    }

    // *****************************
    // ****  Mobile Dropdown   *****
    // *****************************

    // Trigger tab change when dropdown clicked.
    $('.tabs-mobile-nav').change(function () {
        $('#' + $(this).val()).tab('show');
    });

    // Update dropdown when tab shown by API
    $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
        $($(this).closest('.nav-tabs').data('mobile-nav')).val($(this).attr('id'));
    });

    // ************************************
    // ****  Tab Navigation History   *****
    // ************************************

    window.addEventListener('popstate', function () {
        showTab();
    });

    showTab();

    // Add tab changes as history events
    $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
        var stateObject = { 'url': $(this).attr('href') };
        if (w.location.hash && stateObject.url !== w.location.hash) {
            w.history.pushState(stateObject, d.title + ' - ' + this.innerText, w.location.pathname + $(this).attr('href'));
        } else {
            w.history.replaceState(stateObject, d.title + ' - ' + this.innerText, w.location.pathname + $(this).attr('href'));
        }
    });

})(jQuery, window, document);

