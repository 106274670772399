// *******************************************
// ************* Height Matching *************
// *******************************************



    // use data attribute 'height-match-group' on elements to have multiple match groups within one wrapper

    // .height-match-wrapper = parent container
    // .height-match-item = child element to be height-matched using 'min-height'
    // .height-match-ignore = child element to be ignored

    var wrappers = [],
        active = false;

    var init = function() {
        $(".height-match-wrapper").each(function(i, thisWrapper) {
            active = true;
            wrappers[i] = new Object();
            wrappers[i].obj = thisWrapper;
            wrappers[i].objWidth = 0;
            wrappers[i].itemgroups = [];
            wrappers[i].items = [];
            $(this).find(".height-match-item").each(function(x, thisItem) {
                var getGroup = $(thisItem).data('height-match-group');
                var getGroupIndex = -1;
                if(wrappers[i].itemgroups.length > 0) {
                    for(var z = 0 ; z < wrappers[i].itemgroups.length; z++) {
                        if(wrappers[i].itemgroups[z].group == getGroup) getGroupIndex = z;
                    }
                }
                if(getGroupIndex == -1) {
                    var newIndex = wrappers[i].itemgroups.length;
                    wrappers[i].itemgroups[newIndex] = new Object({
                        group : getGroup,
                        items : []
                    });
                    getGroupIndex = newIndex;
                }
                wrappers[i].itemgroups[getGroupIndex].items.push(thisItem);
            });
            wrappers[i].active = wrappers[i].itemgroups.length > 0 ? true : false;
        });
        if(active) {
            update(true);
            $(window).on('load', function () {
                window.requestAnimationFrame(function() {
                    update(true);
                });
            });
            $(window).on('resize', function () {
                window.requestAnimationFrame(function() {
                    update(false);
                });
            });
        }
    };

    var update = function(force) { // bool force will run even if wrapper width hasn't changed
        if(active) {
            for(var i=0; i < wrappers.length; i++) {
                if(wrappers[i].active) {
                    var wrapperWidth = $(wrappers[i].obj).width();
                    if(wrappers[i].objWidth != wrapperWidth || force) {
                        for(var y=0; y < wrappers[i].itemgroups.length; y++) {
                            var itemsGroup = wrappers[i].itemgroups[y].items;
                            // reset heights
                            $(itemsGroup).attr("style", "");
                            // calc new heights
                            var hm_Height = 0;
                            var hm_StartIndex = 0;
                            var hm_Top = 0;
                            for(var x=0; x < itemsGroup.length; x++) {
                                var ignoreItem = $(itemsGroup[x]).hasClass("height-match-ignore");
                                var getTop = ignoreItem ? 0 : Math.floor($(itemsGroup[x]).offset().top);
                                if(getTop > hm_Top) {
                                    hm_Top = getTop;
                                    if(x > 0) {
                                        for(var z = hm_StartIndex; z < x; z++) {
                                            $(itemsGroup[z]).css({"min-height" : hm_Height + "px"});
                                        }
                                        hm_Height = 0;
                                        hm_StartIndex = x;
                                    }
                                    hm_Top = Math.floor($(itemsGroup[x]).offset().top);
                                }
                                var getHeight = ignoreItem ? 0 : Math.ceil($(itemsGroup[x]).outerHeight());
                                if(getHeight > hm_Height) hm_Height = getHeight;
                                // last item?
                                if(x === (itemsGroup.length -1)) {
                                    for(z = hm_StartIndex; z < itemsGroup.length; z++) {
                                        $(itemsGroup[z]).css({"min-height" : hm_Height + "px"});
                                    }
                                }
                            }
                        }
                        // store width
                        wrappers[i].objWidth = wrapperWidth;
                    }
                }
            }
        }
    };

    init();
