

    var $showMoreListing = $('.js-show-more-listing'),
        $showMoreButton = $('.js-show-more-button'),
        hiddenItemClass = 'js-show-more-hidden-item',
        pageSize;

    var isLoading = false;

    function hidePagination() {
        $showMoreButton.hide();
    }

    function showNextPage() {
        var hiddenItems = $showMoreListing.find('.' + hiddenItemClass).slice(0, 10);

        hiddenItems.removeClass(hiddenItemClass).show();

        if (!hiddenItems.length || hiddenItems.length < pageSize) {
            hidePagination();
        }

        isLoading = false;
        isLoading = false;
    }

    function bindEvents() {
        $showMoreButton.on('click tap', function(e) {
            if (isLoading) {
                return;
            }

            isLoading = true;

            showNextPage();
            e.preventDefault();
        });
    }

    function init() {
        if (!$showMoreListing.length) {
            return;
        }

        bindEvents();    

        pageSize = parseInt($showMoreListing.data('size'), 10);
    }

init();