// *********************************************
// ************* Navigation engine *************
// *********************************************

    var n,
        keycodes = {
            'TAB': 9,
            'RETURN': 13,
            'ESC': 27,
            'SPACE': 32,
            'PAGEUP': 33,
            'PAGEDOWN': 34,
            'END': 35,
            'HOME': 36,
            'LEFT': 37,
            'UP': 38,
            'RIGHT': 39,
            'DOWN': 40
        };

    var init = function() {
        n = {
            mobile : true,
            mousemode : false,
            show : $('#header--menu-show'),
            hide : $('#header--menu-hide'),
            active : false,
            subMenuActive : false,
            subMenuActiveIndex : -1,
            menu : $('#header--menu'),
            menuItems : [],
            topMenuItems : $('#header--menu-list .top-level'),
            topMenuItemIndex : 0,
            subMenuItemIndex : 0,
            subMenuTimeout : null,
            resizeMeasure : $('header'),
            breakpoint : 990
        }
        // check off-canvas (mobile) mode
        $(window).on('resize', function () {
            window.requestAnimationFrame(function() {
                check();
            });
        });
        check();
        // loop through menu areas
        $('#header--menu-list li.header--menu-list-item').each(function(i, thisItem) {
            n.menuItems[i] = {
                obj : thisItem,
                hasSubmenu : $(thisItem).hasClass('submenu'),
                link: $(thisItem).hasClass('submenu') ? $(thisItem).find('button') : $(thisItem).find('a').first()
            }
            if(n.menuItems[i].hasSubmenu) {
                n.menuItems[i].submenu = $(thisItem).find('ul');
                n.menuItems[i].submenuLinks = $(thisItem).find('ul li a');
                n.menuItems[i].submenuActive = false;
                $(n.menuItems[i].submenu).removeAttr('hidden').slideUp(0);
                // submenu toggle behaviour
                $(n.menuItems[i].link).on('focus', function () {
                    n.topMenuItemIndex = i;
                }).on('click', function (e) {
                    e.preventDefault();
                    clearTimeout(n.subMenuTimeout);
                    if(!n.mobile && n.menuItems[i].submenuActive && n.mousemode) {
                        // click canonical link in submenu
                        window.location = $(n.menuItems[i].submenuLinks[0]).attr('href');
                    } else {
                        toggleSubmenu(i, !n.menuItems[i].submenuActive);
                    }
                }).on('mouseenter', function () {
                    n.mousemode = true;
                    clearTimeout(n.subMenuTimeout);
                    if(!n.mobile && !n.menuItems[i].submenuActive) toggleSubmenu(i, true);
                }).on('mouseleave', function () {
                    if(!n.mobile && n.menuItems[i].submenuActive) n.subMenuTimeout = setTimeout(
                        function() {
                            hideSubmenu();
                        }, 200
                    );
                });
                $(n.menuItems[i].submenuLinks).each(function(x, thisLink) {
                    $(thisLink).on('focus', function() {
                        n.subMenuItemIndex = x;
                    });
                });
                $(n.menuItems[i].submenu).on('mouseenter', function () {
                    clearTimeout(n.subMenuTimeout);
                }).on('mouseleave', function () {
                    if(!n.mobile && n.menuItems[i].submenuActive) n.subMenuTimeout = setTimeout(
                        function() {
                            hideSubmenu();
                        }, 200
                    );
                })
            } else {
                $(n.menuItems[i].link).on('focus', function () {
                    n.topMenuItemIndex = i;
                    if(!n.mobile) checkSubmenu(i);
                });
            }
            // keyboard controls
            $(n.menuItems[i].obj).on('keydown.ppwop', function(e) {
                var nextIndex;
                switch (e.keyCode) {
                    // ESCAPE closes open submenu
                    case keycodes.ESC:
                        if(n.menuItems[n.topMenuItemIndex].hasSubmenu && n.subMenuActive) {
                            e.preventDefault();
                            // close active submenu
                            toggleSubmenu(n.subMenuActiveIndex, false);
                        }
                        break;
                    // RIGHT moves to the next menu trigger element
                    case keycodes.RIGHT:
                        nextIndex = n.topMenuItemIndex + 1;
                        if(nextIndex >= n.menuItems.length) nextIndex = 0;
                        if(n.menuItems[n.topMenuItemIndex].hasSubmenu) {
                            // close active submenu
                            toggleSubmenu(n.topMenuItemIndex, false);
                        }
                        if(n.menuItems[nextIndex].hasSubmenu) {
                            // open submenu
                            toggleSubmenu(nextIndex, true);
                        } else {
                            $(n.menuItems[nextIndex].link).focus();
                        }
                        n.topMenuItemIndex = nextIndex;
                        e.preventDefault();
                        break;
                    // LEFT moves to the prev menu trigger element
                    case keycodes.LEFT:
                        e.preventDefault();
                        nextIndex = n.topMenuItemIndex - 1;
                        if(nextIndex < 0) nextIndex = (n.menuItems.length - 1);
                        if(n.menuItems[n.topMenuItemIndex].hasSubmenu) {
                            // close active submenu
                            toggleSubmenu(n.topMenuItemIndex, false);
                        }
                        if(n.menuItems[nextIndex].hasSubmenu) {
                            // open submenu
                            toggleSubmenu(nextIndex, true);
                        } else {
                            $(n.menuItems[nextIndex].link).focus();
                        }
                        n.topMenuItemIndex = nextIndex;
                        break;
                    // UP to move through submenu links
                    case keycodes.UP:
                        if(n.subMenuActive) {
                            e.preventDefault();
                            nextIndex = n.subMenuItemIndex - 1;
                            if(nextIndex < 0) nextIndex = (n.menuItems[n.subMenuActiveIndex].submenuLinks.length - 1);
                            $(n.menuItems[n.subMenuActiveIndex].submenuLinks[nextIndex]).focus();
                            n.subMenuItemIndex = nextIndex;
                        }
                        break;
                    // DOWN to move through submenu links
                    case keycodes.DOWN:
                        if(n.subMenuActive) {
                            e.preventDefault();
                            nextIndex = n.subMenuItemIndex + 1;
                            if(nextIndex >= n.menuItems[n.subMenuActiveIndex].submenuLinks.length) nextIndex = 0;
                            $(n.menuItems[n.subMenuActiveIndex].submenuLinks[nextIndex]).focus();
                            n.subMenuItemIndex = nextIndex;
                        }
                        break;
                }
            });
        });
        // button behaviour
        $(n.show).on('click', function() {
            if(n.mobile && !n.active) {
                // show menu
                toggleMenu(true);
            }
        });
        $(n.hide).on('click', function() {
            if(n.mobile && n.active) {
                // hide menu
                toggleMenu(false);
            }
        });
        // document click/touch (to cancel submenu)
        $('body').on('click touchstart', function (e) {
            var $target = $(e.target);
            if (!n.mobile && n.subMenuActive) {
                if (!($target.is(n.menuItems[n.subMenuActiveIndex].link) || $target.parents().is(n.menuItems[n.subMenuActiveIndex].link) || $target.parents().is(n.menuItems[n.subMenuActiveIndex].submenu))) {
                    hideSubmenu();
                }
            }
        });
        // Keyboard controls
        $(n.menu).on('keydown.ppwop', function(e) {
            if(n.active && n.mobile) {
                switch (e.keyCode) {
                    // ESCAPE out of menu if not submenus or dropdowns are open
                    case keycodes.ESC:
                        e.preventDefault();
                        if(!$(n.menu).find('.dropdown-toggle[aria-expanded="true"]').length > 0
                            && !$(n.menu).find('.header--menu-list-item.submenu.show').length > 0) { // check no dropdowns are open
                            // hide whole mobile menu
                            toggleMenu(false);
                        }
                        break;
                }
            }
        });
        $(n.hide).on('keydown.ppwop', function(e) {
            if(n.active && n.mobile) {
                switch (e.keyCode) {
                    // TAB loops back to first item in mobile menu
                    case keycodes.TAB:
                        e.preventDefault();
                        $(n.menu).find('button, a').first().focus();
                        break;
                }
            }
        });
    };

    var toggleMenu = function(mode) {
        if(n.mobile && mode) {
            // show menu
            $(n.menu).addClass('active').attr('aria-hidden', false);
            $(n.show).attr('aria-expanded', true);
            n.active = true;
            $(n.topMenuItems[n.topMenuItemIndex]).focus();
        } else if(n.mobile) {
            // hide menu
            $(n.menu).removeClass('active').attr('aria-hidden', true);
            $(n.show).attr('aria-expanded', false).focus();
            n.active = false;
            n.topMenuItemIndex = 0;
        }
    };

    var toggleSubmenu = function(menuIndex, mode) {
        if(n.menuItems[menuIndex].submenuActive && !mode) {
            // hide submenu
            n.subMenuActive = false;
            n.subMenuActiveIndex = -1;
            $(n.menuItems[menuIndex].link).focus();
            $(n.menuItems[menuIndex].submenu).slideUp((n.mobile ? 300 : 0), function() {
                $(n.menuItems[menuIndex].obj).removeClass('show');
            });
            $(n.menuItems[menuIndex].submenu).attr('aria-hidden', true);
            $(n.menuItems[menuIndex].link).attr('aria-expanded', false);
            n.menuItems[menuIndex].submenuActive = false;
        } else if(!n.menuItems[menuIndex].submenuActive && mode) {
            // hide previous submenu?
            if(n.subMenuActive && n.subMenuActiveIndex != menuIndex) {
                hideSubmenu();
            }
            // show submenu
            n.subMenuItemIndex = 0;
            $(n.menuItems[menuIndex].obj).addClass('show');
            $(n.menuItems[menuIndex].submenu).slideDown((n.mobile ? 300 : 0));
            $(n.menuItems[menuIndex].submenu).attr('aria-hidden', false);
            $(n.menuItems[menuIndex].link).attr('aria-expanded', true);
            n.menuItems[menuIndex].submenuActive = true;
            $(n.menuItems[menuIndex].submenuLinks.get(0)).focus();
            n.subMenuItemIndex = 0;
            n.subMenuActive = true;
            n.subMenuActiveIndex = menuIndex;
            n.topMenuItemIndex = menuIndex;
        }
    };

    var hideSubmenu = function() {
        var i = n.subMenuActiveIndex;
        $(n.menuItems[i].submenu).slideUp((n.mobile ? 300 : 0), function() {
            $(n.menuItems[i].obj).removeClass('show');
        });
        $(n.menuItems[i].submenu).attr('aria-hidden', true);
        $(n.menuItems[i].link).attr('aria-expanded', false);
        n.menuItems[i].submenuActive = false;
        n.subMenuActive = false;
        n.subMenuActiveIndex = -1;
    };

    var checkSubmenu = function(menuIndex) {
        if(menuIndex != n.subMenuActiveIndex && n.subMenuActive) {
            hideSubmenu();
        }
    };

    var check = function() {
        var lastCheck = n.mobile;
        n.mobile = $(n.resizeMeasure).width() < n.breakpoint;
        if(n.mobile != lastCheck) {
            // reset
            if(n.mobile) {
                // hide menu
                $(n.menu).removeClass('active').attr('aria-hidden', true);
                $(n.show).attr('disabled', false).attr('aria-expanded', false);
                n.active = false;
                n.topMenuItemIndex = 0;
            } else {
                // update aria
                $(n.menu).attr('aria-hidden', false);
                $(n.show).attr('aria-expanded', true).attr('disabled', true);
            }
        }
    };

    if(document.getElementById('header--menu')) init();