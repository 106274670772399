// **************************************
// *****  Horizontal Carousel List  *****
// **************************************

var c = [];

// calculate breakpoint
var getBreakpoint = function() {
    var w = $('header').width();
    if(w < 576) {
        return 'xs';
    } else if(w < 768) {
        return 'sm';
    } else if(w < 992) {
        return 'md';
    } else if(w < 1200) {
        return 'lg';
    } else {
        return 'xl';
    }
}

// init
var init = function() {
    $('.horizontal-carousel-list').each(function(i, thisCarousel) {
        if($(thisCarousel).find('li').length > 0) {
            c[i] = new Object({
                wrapper : thisCarousel,
                index : i,
                breakpoint: '',
                items : [],
                itemsCount : 0,
                itemWidth: 0,
                displayCount: 1,
                offsetIndex : 0,
                row: $(thisCarousel).find('ul')[0],
                touchStartX : 0,
                timeout: null
            });
            // add id
            $(thisCarousel).attr('id','horizontal-carousel-list-' + i);
            // add buttons
            $(thisCarousel).find('.horizontal-carousel-list--nav')
                .append('<button id="horizontal-carousel-list--nav-l-' + i + '" class="btn btn-primary horizontal-carousel-list--nav-l" aria-controls="horizontal-carousel-list-' + i + '" disabled="disabled"><i class="fa fa-chevron-left" aria-hidden="true"></i><span class="sr-only">' + 'Show previous items' + '</span></button>')
                .append('<button id="horizontal-carousel-list--nav-r-' + i + '" class="btn btn-primary horizontal-carousel-list--nav-r" aria-controls="horizontal-carousel-list-' + i + '" disabled="disabled"><i class="fa fa-chevron-right" aria-hidden="true"></i><span class="sr-only">' + 'Show next items' + '</span></button>');
            c[i].navButtonLeft = $('#horizontal-carousel-list--nav-l-' + i);
            c[i].navButtonRight = $('#horizontal-carousel-list--nav-r-' + i);
            $(c[i].navButtonLeft).on('click', function() {
                nav(i, false);
            });
            $(c[i].navButtonRight).on('click', function() {
                nav(i, true);
            });
            // get list data
            $(thisCarousel).find('li').each(function (x, thisItem) {
                c[i].items[x] = new Object({
                    obj: thisItem,
                    index : x
                });
            });
            c[i].itemsCount = c[i].items.length;
            // position
            refresh(i);
            // resize behaviour
            $(window).resize(function () {
                clearTimeout(c[i].timeout);
                c[i].timeout = setTimeout(function() {
                    refresh(i);
                }, 100);
                
            });
            // add swipe nav support
            $(c[i].row).on('touchstart', function (event) {
                var e = event.originalEvent;
                if (e.touches.length == 1) {
                    var touch = e.touches[0];
                    c[i].touchStartX = touch.pageX;
                }
            }).on('touchmove', function (event) {
                var e = event.originalEvent;
                $(c[i].row).find('a').blur();
                if (c[i].touchStartX != null) {
                    var touchCurrentX = e.changedTouches[0].pageX;
                    if ((touchCurrentX - c[i].touchStartX) > 80) {
                        c[i].touchStartX = null;
                        nav(i, false);
                    } else if ((c[i].touchStartX - touchCurrentX) > 80) {
                        c[i].touchStartX = null;
                        nav(i, true);
                    }
                }
            }).on('touchend', function () {
                $(c[i].row).find('a').blur();
                c[i].touchStartX = null;
            });
        }
    });
};

// refresh
var refresh = function(getIndex) {
    var thisList = c[getIndex];
    var b = getBreakpoint();
    if((b == 'xs' || b == 'sm' || b == 'md') || b != thisList.breakpoint) {
        thisList.breakpoint = b;
        switch(b) {
        case 'sm':
            thisList.displayCount = 2;
            break;
        case ('md'):
            thisList.displayCount = 3;
            break;
        case ('lg'):
            thisList.displayCount = 3;
            break;
        case 'xl':
            thisList.displayCount = 3;
            break;
        default:
            thisList.displayCount = 1;
            break;
        }
        thisList.itemWidth = $(thisList.items[0].obj).outerWidth();
        // position items
        for (var i = 0; i < thisList.itemsCount; i++) {
            $(thisList.items[i].obj).css({
                'left': (i * thisList.itemWidth) + 'px'
            });
        }
        // update
        update(getIndex);
    }
};


var nav = function(getIndex, mode) {
    var thisList = c[getIndex];
    if(mode && thisList.offsetIndex < Math.floor(thisList.itemsCount/thisList.displayCount)) {
        thisList.offsetIndex +=1;
        update(getIndex);
    } else if(!mode && thisList.offsetIndex > 0) {
        thisList.offsetIndex -=1;
        update(getIndex);
    }
};

var update = function(getIndex) {
    var thisList = c[getIndex];
    var offsetMax = Math.ceil(thisList.itemsCount/thisList.displayCount) -1;
    // offset
    if (thisList.offsetIndex > offsetMax) thisList.offsetIndex = offsetMax;
    var shift = (0 - (thisList.offsetIndex * (thisList.displayCount * thisList.itemWidth)));
    $(thisList.row).css({'transform' : 'translate3d(' + shift + 'px,0,0)'});
    // update aria
    var activeStartIndex = thisList.offsetIndex * thisList.displayCount;
    var activeEndIndex = (thisList.offsetIndex + 1) * thisList.displayCount;
    for (var i = 0; i < thisList.itemsCount; i++) {
        var isActive = (i >= activeStartIndex && i < activeEndIndex);
        $(thisList.items[i].obj).attr('aria-hidden', !isActive);
    }
    // set focus on first active item
    $(thisList.items[activeStartIndex].obj).focus();
    // update nav buttons
    $(thisList.navButtonLeft).attr('disabled', thisList.offsetIndex == 0);
    $(thisList.navButtonRight).attr('disabled', thisList.offsetIndex >= offsetMax);
};

init();
