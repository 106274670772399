function createCookie(name, value, length) {
    var date = new Date();

    date.setTime(date.getTime() + (length * 24 * 60 * 60 * 1000));
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
}

$('#cookies-bar-close, #cookies-read-more').click(function () {
    if ($) {
        $('#cookie-bar').animate({ 'height': '0' }, 300, function () {
            $('#cookie-bar-area').remove();
        });
    }

    createCookie('cookies-bar-' + $(this).data('cookies-id'), 'dismiss', 1);
});
