// *****************************************
// ************* Image Gallery *************
// *****************************************

import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

var jsAdded = false,
    photoswipeOverlayCode = "<div class='pswp' tabindex='-1' role='dialog' aria-hidden='true'><div class='pswp__bg'></div><div class='pswp__scroll-wrap'><div class='pswp__container'><div class='pswp__item'></div><div class='pswp__item'></div><div class='pswp__item'></div></div><div class='pswp__ui pswp__ui--hidden'><div class='pswp__top-bar'><div class='pswp__counter'></div><button class='pswp__button pswp__button--close' title='Close (Esc)'></button><button class='pswp__button pswp__button--share' title='Share'></button><button class='pswp__button pswp__button--fs' title='Toggle fullscreen'></button><button class='pswp__button pswp__button--zoom' title='Zoom in/out'></button><div class='pswp__preloader'><div class='pswp__preloader__icn'><div class='pswp__preloader__cut'><div class='pswp__preloader__donut'></div></div></div></div></div><div class='pswp__share-modal pswp__share-modal--hidden pswp__single-tap'><div class='pswp__share-tooltip'></div></div><button class='pswp__button pswp__button--arrow--left' title='Previous (arrow left)'></button><button class='pswp__button pswp__button--arrow--right' title='Next (arrow right)'></button><div class='pswp__caption'><div class='pswp__caption__center'></div></div></div></div></div>";

// init
var init = function () {
    if ($(".image-gallery:not('.image-gallery-installed')").length > 0) {
        // do we need to install photowipe?
        if (!jsAdded) {
            // add the photoswipe overlay code to the dom
            $("body").append(photoswipeOverlayCode);
            // set the flag so we only do this once
            jsAdded = true;
        }
        // update widths
        updateWidths();
        $(window).on('load resize', function () {
            updateWidths();
        });
        // setup
        setup();
    }
}

// update widths
var updateWidths = function() {
    var bodyWidth = $('body').innerWidth();
    $(".horizontal-gallery--grid-wrapper .horizontal-gallery").css({
        'width' : bodyWidth + 'px'
    });
}

// setup
var setup = function () {
    // full-width sizing
    // gallery setup
    $(".image-gallery:not('.image-gallery-installed')").each(function (i, thisGallery) {
        var $pic = $(this),
            getItems = function () {
                var items = [];
                $pic.find('a').each(function (x) {
                    var $href = $(this).attr('href');
                    var item = {
                        src: $href,
                        w: 0,
                        h: 0
                    }
                    if ($(this).data('size')) {
                        var size = $(this).data('size').split('x');
                        item.w = size[0];
                        item.h = size[1];
                    }
                    items.push(item);
                    $(this).attr('data-index', x);
                });
                return items;
            }
        var items = getItems();
        $pic.on('click', 'a', function (event) {
            event.preventDefault();
            var $index = $(this).data('index');
            var options = {
                loop: false,
                index: $index,
                bgOpacity: 0.95,
                showHideOpacity: true
            }
            var lightBox = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, items, options);
            lightBox.listen('gettingData', function (index, item) {
                if (item.w < 1 || item.h < 1) { // unknown size
                    var img = new Image();
                    img.onload = function () { // will get size after load
                        item.w = this.width; // set image width
                        item.h = this.height; // set image height
                        lightBox.invalidateCurrItems(); // reinit Items
                        lightBox.updateSize(true); // reinit Items
                    }
                    img.src = item.src; // let's download image
                }
            });
            lightBox.init();
        });
        // add class to this gallery so we don't process it again
        $(thisGallery).addClass("image-gallery-installed");
    });
}

init();