// scroll-to-anchor links 
    
    $('.scroll-to').on('click', function(event) {
        var targetID = $(this).attr('href');
        if(document.getElementById(targetID.replace('#',''))) {
            event.preventDefault();
            setTimeout(function() {
                var targetTop = $(targetID).offset().top;
                var windowHeight = $(window).height();
                var windowInnerHeight = window.innerHeight ? window.innerHeight : windowHeight;
                var scrollDistance = window.pageYOffset;
                var scrollMax = $('body').height() - windowInnerHeight;
                var newY = targetTop < scrollMax ? targetTop : scrollMax;
                $('html, body').animate({ scrollTop: scrollDistance }, 0).animate({ scrollTop: newY }, 1000);
            }, 200);
        }
    });
